import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import ProductList from '../components/Products/ProductList';
import { decodeEntities } from '../utils/helpers';

export default class ProductIndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { pathPrefix } = pageContext
    const { wordpressPost: page, allWordpressWpProducts, categoryFilter, categories, wordpressWpSettings } = data;
    const { title, yoast, acf } = page ? page : { title: null, yoast: {}, acf: {} }
    const { title: siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressWpProducts
    const isParent = location.pathname.split('/').length <= 3 && location.pathname !== '/products/'  //Check url for depth
    return (
      <Layout location={location}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `Products | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
        />
        <PageHeader headerTitle="Products" location={location} />
        <ProductList
        posts={posts}
        title="Latest products"
        pageContext={pageContext}
        categoryFilter={categoryFilter}
        categories={categories.edges}
        siteMetadata={wordpressWpSettings}
        pathPrefix="/products/"
        isParent={isParent}
        />
      </Layout>
    )
  }
}

ProductIndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProducts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProductIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title,
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressPage(slug: {eq: "products"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    categoryFilter: allWordpressWpProducts {
      nodes {
        product_category
      }
    }
    allWordpressWpProducts(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProductListFields
        }
      }
    }
    categories: allWordpressWpProductCategory(filter: {slug: {ne: "uncategorised"}}) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
